.login-page {
  height: 100%;
}

.login-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.login-container .cover-container {
  display: flex;
  flex: 1;
  background: url(../../assets/images/arcestra-cover.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  justify-content: center;
}

.login-container .cover-container .cover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.3;
}

.login-container .cover-container .brand-container img {
  z-index: 1;
  position: absolute;
  top: 20%;
  left: 10%;
  width: 300px;
}

.login-container .login-form-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.login-container .login-form-container .buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login-container .login-form-container .card {
  border: 0;
}

.login-container .login-form-container .card .form-group {
  position: relative;
}

.login-container .login-form-container .card input {
  padding: 12px 20px 12px 110px;
  outline: none;
}

.login-container .login-form-container .card label {
  position: absolute;
  left: 20px;
  top: 12px;
  color: #989898;
  font-family: roboto-regular;
}

.login-container .login-form-container .card button {
  min-width: 150px;
}

.login-container .login-form-container .card .btn-forgot-password {
  font-size: 14px;
  color: #989898;
  cursor: pointer;
  margin-left: 30px;
}

.login-container .login-form-container .error-container {
  display: flex;

  font-size: 14px;
  margin: 8px 0;
  color: #f00;
}

/* Forgot Password Container */

.login-container .forgot-password-container {
}

.login-container .forgot-password-container .card input {
  padding-left: 110px !important;
}

.login-container .forgot-password-container .buttons-container button {
  margin-right: 10px;
}

.login-container
  .forgot-password-container
  .forgot-password-success-notification-container {
  color: #989898;
}

.login-container
  .forgot-password-container
  .forgot-password-success-notification-container
  h3 {
  color: #323c47;
  font-family: fira-sans-condensed-light;
  text-align: center;
  margin-bottom: 20px;
}

.login-container
  .forgot-password-container
  .forgot-password-success-notification-container
  a.btn-login-redirect {
  color: #39aabb;
  font-weight: bold;
  cursor: pointer;
}

.login-message {
  position: relative;
  top: -30px;
}

/* Recaptcha Style */

.rc-anchor {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
