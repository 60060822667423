.app-container {
  height: 100%;
}

.app-container > .loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #39aabb;
  color: #fff;
}

.route-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
}

.generic-loader {
  display: flex;
  justify-content: center;
}

.generic-loader.align-top {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.generic-loader.align-center {
  align-items: center;
}

.app-container.container-fluid.no-padding {
  padding: 0;
}

.display-flex-row {
  display: flex;
  flex-direction: row;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.justify-content-end {
  display: flex;
  justify-content: flex-end;
}

.justify-content-start {
  display: flex;
  justify-content: flex-start;
}

.container-fluid-no-padding {
  padding-left: 0;
  padding-right: 0;
}

.display-hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

/* Page Header Stats Table Style */

.page-header .stats-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.page-header .stats-container table.stats th {
  font-family: roboto-bold;
  font-size: 16px;
  padding: 0 30px 10px 0;
}

.page-header .stats-container table.stats td {
  font-family: roboto-regular;
  font-size: 14px;
  color: #989898;
  padding-right: 30px;
  min-width: 100px;
  width: 230px;
}

.page-header .stats-container table.stats tr:first-child td {
  font-family: roboto-regular;
  font-size: 22px;
  color: #584889;
}

.page-header .stats-container table.stats tr:first-child a {
  font-family: roboto-regular;
  font-size: 22px;
  color: #584889;
  text-decoration: none;
}

.page-header .stats-container table.stats tr td.separator {
  min-width: 30px !important;
  width: 30px !important;
}

/* Page Header Stats Table Style ends */

/* Generic Table CSS starts */

table.list-view-table {
  flex-grow: 1;
  background: #fff;
  border-collapse: collapse;
  border: 0;
  font-size: 12px;
  border: 1px solid #ddd;
  width: 100%;
}

table.list-view-table th,
table.list-view-table td {
  padding-left: 20px;
  padding-right: 20px;
}

table.list-view-table thead th {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: roboto-bold;
  font-size: 16px;
  vertical-align: top;
}

table.list-view-table thead th.sortable {
  cursor: pointer;
}

table.list-view-table thead th .icon-sort {
  display: block;
  margin-right: 10px;
}

table.list-view-table tr {
  border-bottom: 1px solid #eee;
}

table.list-view-table tbody:last-child tr:last-child {
  border-bottom: 0;
}

table.list-view-table tr td {
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Roboto-Regular;
  font-size: 16px;
  color: #989898;
}

table.list-view-table tr td .subtext {
  font-size: 12px;
}

/* Arcestra Toast Styles */

.arcestra-toast-container {
  min-width: 400px;
  margin-left: -200px;
  z-index: 1100; /* To ensure it renders over the dialogs too */
}

.arcestra-toast-container .toastify__body {
  width: 100%;
  text-align: center;
  color: #989898;
}

.arcestra-toast-container .toastify-content--error .toastify__body {
  color: #fff;
}

.arcestra-toast-container .arcestra-toast {
  min-height: 70px;
}

.arcestra-toast-container .arcestra-toast-progress {
  background: #584889;
}

.arcestra-toast-container .toastify-content--default {
  border: 1px solid #eee;
}

.arcestra-toast-container .toastify-content .toast-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arcestra-toast-container .toastify-content .toast-content-container i.fa {
  margin-right: 10px;
}

.arcestra-toast-container
  .toastify-content
  .toast-content-container
  i.fa.fa-exclamation-circle {
  color: #f00;
}

.arcestra-toast-container .connectivity-notification-toast {
  background: #e06475;
}

.arcestra-toast-container .connectivity-notification-toast i.fa {
  margin-right: 10px;
}

/* Arcestra Collapsio Style Starts */

.Collapsio {
  margin-bottom: 2px;
  border: 1px solid #ccc;
}

.Collapsio .Collapsio__trigger .Collapsio__trigger__inner {
  padding: 35px 40px 33px 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Collapsio .Collapsio__trigger.is-open {
}

.Collapsio .Collapsio__trigger .Collapsio__trigger__inner i.fa {
  margin-right: 20px;
  font-size: 20px;
}

.Collapsio .Collapsio__trigger.is-closed {
}

.Collapsio .Collapsio__contentOuter {
}

.Collapsio .Collapsio__contentOuter .Collapsio__contentInner {
  background: #fff;
  padding: 0 40px 40px 40px;
}

/* Arcestra Collapsio Style Ends */

button.btn {
  cursor: pointer;
  outline: none;
}

button.btn.btn-primary {
  background: none;
  border: 1px solid #888;
  color: #888;
}

button.btn.btn-primary:focus {
  box-shadow: 0 0 0 3px rgba(136, 136, 136, 0.5);
}

button.btn.btn-secondary {
}

button.btn.btn-danger {
  border: 1px solid #674083;
  background: #674083;
}

button.btn.btn-danger:focus {
  box-shadow: 0 0 0 3px rgba(103, 64, 131, 0.5);
}

/* Icon Styles */

span.icon {
  display: block;
}

span.icon.icon-sort {
  background: url("./assets/images/sprites.png") no-repeat -133px -67px;
  min-width: 13px;
  height: 17px;
}

span.icon.icon-vertical-dots {
  background: url("./assets/images/sprites.png") no-repeat -106px -189px;
  width: 6px;
  height: 22px;
}

span.icon.icon-edit {
  background: url("./assets/images/sprites.png") no-repeat -192px -97px;
  width: 14px;
  height: 15px;
}

span.icon.icon-delete {
  background: url("./assets/images/sprites.png") no-repeat -100px -283px;
  width: 12px;
  height: 12px;
}

span.icon.icon-plus {
  background: url("./assets/images/sprites.png") no-repeat -68px -282px;
  width: 14px;
  height: 13px;
}

span.icon.icon-copy {
  background: url("./assets/images/sprites.png") no-repeat -191px -127px;
  width: 16px;
  height: 17px;
}

span.icon.icon-cut {
  background: url("./assets/images/sprites.png") no-repeat -191px -160px;
  width: 16px;
  height: 14px;
}

span.icon.icon-arrow-left {
  background: url("./assets/images/sprites.png") no-repeat -192px -191px;
  width: 14px;
  height: 14px;
  transform: rotate(180deg);
}

span.icon.icon-arrow-right {
  background: url("./assets/images/sprites.png") no-repeat -192px -191px;
  width: 14px;
  height: 14px;
}

span.icon.icon-carrot-left {
  background: url("./assets/images/sprites.png") no-repeat -67px -189px;
  width: 8px;
  height: 12px;
}

span.icon.icon-carrot-right {
  background: url("./assets/images/sprites.png") no-repeat -67px -189px;
  width: 8px;
  height: 12px;
  transform: rotate(180deg);
}

span.icon.icon-carrot-dowm-sm {
  background: url("./assets/images/sprites.png") no-repeat -98px -158px;
  width: 10px;
  height: 6px;
}

span.icon.icon-notification {
  background: url("./assets/images/sprites.png") no-repeat -160px -65px;
  width: 14px;
  height: 17px;
}

span.icon.icon-notification-off {
  background: url("./assets/images/sprites.png") no-repeat -191px -65px;
  width: 15px;
  height: 17px;
}

span.icon.icon-collapse {
  background: url("./assets/images/sprites.png") no-repeat -98px -158px;
  width: 10px;
  height: 6px;
}

span.icon.icon-office {
  background: url("./assets/images/sprites.png") no-repeat -248px -91px;
  width: 62px;
  height: 57px;
}

span.icon.icon-retail {
  background: url("./assets/images/sprites.png") no-repeat -248px -152px;
  width: 61px;
  height: 57px;
}

span.icon.icon-industrial {
  background: url("./assets/images/sprites.png") no-repeat -248px -213px;
  width: 63px;
  height: 57px;
}

span.icon.icon-residential {
  background: url("./assets/images/sprites.png") no-repeat -322px -91px;
  width: 59px;
  height: 57px;
}

span.icon.icon-land {
  background: url("./assets/images/sprites.png") no-repeat -321px -151px;
  width: 58px;
  height: 57px;
}

span.icon.icon-star {
  background: url("./assets/images/sprites.png") no-repeat -157px -187px;
  width: 26px;
  height: 24px;
}

span.icon.icon-star-inverted {
  background: url("./assets/images/sprites.png") no-repeat no-repeat -126px -187px;
  width: 26px;
  height: 24px;
}

span.icon.icon-building {
  background: url("./assets/images/sprites.png") no-repeat -68px -251px;
  width: 14px;
  height: 17px;
}

span.icon.icon-building-nav {
  background: url("./assets/images/sprites.png") no-repeat -3px -127px;
  width: 25px;
  height: 22px;
}

span.icon.icon-archive {
  background: url("./assets/images/sprites.png") no-repeat -129px -252px;
  width: 16px;
  height: 15px;
}

span.icon.icon-group {
  background: url("./assets/images/sprites.png") no-repeat -192px -251px;
  width: 15px;
  height: 15px;
}

span.icon.icon-alphabets {
  background: url("./assets/images/sprites.png") no-repeat -222px -251px;
  width: 16px;
  height: 15px;
}

span.icon.icon-home {
  background: url("./assets/images/sprites.png") no-repeat -191px -282px;
  width: 16px;
  height: 13px;
}

span.icon.icon-location-drop {
  background: url("./assets/images/sprites.png") no-repeat -225px -282px;
  width: 11px;
  height: 15px;
}

span.icon.icon-magnifying-glass {
  background: url("./assets/images/sprites.png") no-repeat -97px -64px;
  width: 23px;
  height: 23px;
}

span.icon.icon-globe-white {
  background: url("./assets/images/sprites.png") no-repeat -66px -95px;
  width: 23px;
  height: 24px;
}

span.icon.icon-globe-inverted {
  background: url("./assets/images/sprites.png") no-repeat -97px -95px;
  width: 23px;
  height: 24px;
}

span.icon.icon-folder {
  background: url("./assets/images/sprites.png") no-repeat -130px -129px;
  width: 19px;
  height: 17px;
}

span.icon.icon-radio-sm {
  background: url("./assets/images/sprites.png") no-repeat -395px -127px;
  width: 15px;
  height: 15px;
}

span.icon.icon-radio-sm.selected {
  background: url("./assets/images/sprites.png") no-repeat -395px -156px;
}

span.icon.icon-radio-lg {
  background: url("./assets/images/sprites.png") no-repeat -395px -63px;
  width: 18px;
  height: 18px;
}

span.icon.icon-radio-lg.selected {
  background: url("./assets/images/sprites.png") no-repeat -395px -97px;
}

span.icon.icon-merge {
  background: url("./assets/images/sprites.png") no-repeat -395px -187px;
  width: 16px;
  height: 20px;
}

/* Page Overlay Styles */

.page-overlay-container {
  position: fixed;
  left: 80px;
  right: 0;
  top: 160px;
  bottom: 0;
  z-index: 5;
  background: #f7f7f9;
}

.page-overlay-container.cover-context-pane {
  top: 80px;
}

.page-overlay-container .page-overlay-container-content {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* Form Field Styles */

.form-group {
  position: relative;
}

.form-group i.fa.error,
.form-group i.fa.error {
  position: absolute;
  right: 11px;
  top: 13px;
  font-size: 12px;
  color: #f00;
}

.form-group .input-group .input-group-addon {
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-right: 0;
}

/* React tooltip Styles */
.__react_component_tooltip.place-right {
  margin-left: 20px !important;
}

/* Redux Form Components */
.redux-datepicker-container .react-datepicker__input-container {
  width: 100%;
}

.redux-datepicker-container .react-datepicker-wrapper {
  display: block;
}

.redux-datepicker-container input.date-picker-input {
  width: 124px;
  border-radius: 4px;
  border: 1px solid #00000026;
  padding: 6px 12px;
  outline: none;
  font-family: roboto-light;
  color: #495057;
  font-size: 16px;
}

.redux-button-switch-container i.fa.error {
  position: absolute;
  top: 40px;
  left: 160px;
}

.redux-button-switch-container .__react_component_tooltip.place-right {
  margin-left: -155px !important;
}

input.rc-time-picker-input {
  display: block;
  width: 96px;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: 0.25rem;
  height: unset;
  outline: none;
}

.rc-time-picker-panel .rc-time-picker-panel-select {
  height: 144px;
}

.redux-select-container i.fa.error {
  right: -2px !important;
}

.redux-select-container .__react_component_tooltip.place-right {
  margin-left: 12px !important;
}

.redux-select-container.error .Select-control {
  border: 1px solid #f00;
}

.error-container i.fa.error {
  font-size: 12px;
  color: #f00;
}

/* Editable Field Style Starts */

.editable-field-widget {
  display: inline-block;
}

.editable-field-widget .editable-field {
  cursor: pointer;
}

.editable-field-widget:hover .clickable-readonly {
  border-bottom: 1px dashed #ccc;
}

.editable-field-widget .editable-field .clickable-placeholder {
  font-style: italic;
  font-size: 12;
  border-bottom: 1px dashed #ccc;
}

.editable-field-widget input {
  border: 0;
  outline: none;
  border-bottom: 1px dashed #ccc;
  background: none;
}

.editable-field-widget input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ccc;
  font-style: italic;
}

.editable-field-widget input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc;
  font-style: italic;
}

.editable-field-widget input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ccc;
  font-style: italic;
}

.editable-field-widget input:-moz-placeholder {
  /* Firefox 18- */
  color: #ccc;
  font-style: italic;
}

/* Editable Field Style Ends */

/* No image placeholder style */
.no-image-placeholder-container {
  background: #ddd;
  color: #bbb;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: bold;
}

/* HTML Markup Boxes */
.render-HTML-markup ul {
  padding-left: 40px;
  list-style: unset;
}

.render-HTML-markup ul li,
.render-HTML-markup ol li {
  padding: 5px 0;
}

/* Progress Dialog Styles */

.progress-dialog .progress-dialog-content {
  display: flex;
}

.progress-dialog .progress-dialog-content .message-container {
  flex: 1;
  display: flex;
  justify-content: center;
  height: 120px;
  align-items: center;
  font-family: roboto-regular;
  flex-direction: column;
  padding-top: 20px;
}

.progress-dialog .progress-dialog-content .message-container .message-text {
  margin-bottom: 20px;
}

.progress-dialog .progress-dialog-content .progress-controls .icon {
  cursor: pointer;
}

.tooltip-text {
  color: white;
}
